@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
body {
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
    font-family: 'Bebas Neue', 'Lato', sans-serif;
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}


/* .rotate {
    position: relative;
    width: auto;
    height: auto;
} */

CircleLoader button {
    position: relative;
    outline: none;
    border: none;
}

input {
    outline: none;
    border: none;
}

.logo img {
    width: 140px;
}

#main {
    width: 100%;
    height: 768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./images/IMG_2280.jpg');
    /* aqui cambiamos el background de la pagina principal */
}


/* con esto el video va a cubrir toda la parte de la pantalla
y se va adaptar al tamano del dispositivo */

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}

nav.active {
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
    background-color: #ffffff;
}

nav ul {
    display: flex;
}

nav ul li a {
    font-family: 'Bebas Neue';
    height: 40px;
    line-height: 43px;
    margin: 3px;
    padding: 0px 22px;
    display: flex;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #3d2514;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}

nav ul li a:hover {
    background-color: #bf2222;
    /* aqui cambiamos el efecto cuando se pasa el mouse por el  */
    color: #ffffff;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    transition: all ease 0.2s;
}

nav .menu-btn,
.menu-icon {
    display: none;
}

.header-heading {
    font-family: 'Montserrat';
    width: 900px;
    position: absolute;
    left: 25%;
    top: 55%;
    text-align: left;
    transform: translate(-25%, -55%);
}

.header-heading span {
    font-family: 'Alfa Slab One';
    color: #fafffe;
    border-bottom: 2px solid #3d2514;
}

.header-heading .details {
    font-size: 23px;
    color: #180202;
    /*cambiar el color al texto a la tercera linea del header*/
}

.header-heading h3 {
    font-family: 'Bebas Neue';
    font-size: 2.3rem;
    margin: 0px;
    letter-spacing: 2px;
    color: #021423;
}

.header-heading h1 {
    font-family: 'Alfa Slab One';
    font-size: 4rem;
    margin: 0px;
    letter-spacing: 2px;
    color: #a82424;
}

.header-btns {
    display: flex;
    margin-top: 40px;
}

.luis {
    color: #e11717;
}

.header-btn {
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-family: 'Lato';
    background-color: #bf2222;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
    color: #ffffff;
}

.header-btn:hover {
    background-color: transparent;
    transition: all ease 0.5s;
    color: #3d2514;
    border: 2px solid #bf2222;
}

#products {
    width: 100%;
    height: 120vh;
    box-sizing: border-box;
    font-family: 'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    background-color: #ffffff;
}

#products h1 {
    font-family: 'Alfa Slab One';
    letter-spacing: 2px;
    color: #3d2514;
    font-size: 3rem;
}

#products p {
    font-family: 'Bebas Neue';
    letter-spacing: 1px;
    color: #3d2514;
    font-size: 0.8rem;
    margin-top: -15px;
    margin-bottom: 35px;
}

.a-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    /* conesto podemos cuadrar la pantalla para que se visualizen los productos correctamente via pc */
}

.a-box {
    background-color: #ffffff;
    width: 300px;
    height: 435px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}

.a-b-img {
    width: 100%;
    height: 60%;
}

.a-b-img img {
    padding: 15px;
    margin-top: 40px;
    width: 100%;
    height: 100%;
}

.a-box:hover {
    box-shadow: 2px 2px 12px rgba(184, 98, 0, 0.445);
}

.a-b-text {
    width: 100%;
    height: 40%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.a-b-text h2 {
    color: #3d2514;
}

.productbox-button {
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: 'Lato';
    background-color: #bf2222;
    box-shadow: 5px 10px 30px rgba(255, 103, 2, 0.336);
    border-radius: 5px;
    color: #ffffff;
}

.productbox-button:hover {
    background-color: transparent;
    transition: all ease 0.5s;
    color: #3d2514;
    border: 2px solid #3d2514;
}

#about {
    margin-top: 100px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 5% 0px 5%;
    position: relative;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
}

.about-text {
    width: 45%;
    color: #fff;
    text-align: left;
}

.about-text h1 {
    font-size: 3.5rem;
    color: #3d2514;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    font-family: 'Alfa Slab One';
}

.about-text p {
    font-family: 'Bebas Neue';
    letter-spacing: 1px;
    color: #252525;
    font-size: 1.2rem;
}

.about-text button {
    margin-top: 20px;
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: 'Lato';
    background-color: #bf2222;
    box-shadow: 5px 10px 30px rgba(255, 103, 2, 0.336);
    border-radius: 5px;
    color: #ffffff;
}

.about-text button:hover {
    background-color: transparent;
    transition: all ease 0.5s;
    color: #3d2514;
    border: 2px solid #3d2514;
}

.about-image {
    width: 50%;
}

.about-image img {
    width: 600px;
}

#contact {
    margin-top: 120px;
    background-image: url(./images/FOO1.jpg);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#contact h1 {
    font-size: 3.5rem;
    color: #bc1d0b;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    font-family: 'Alfa Slab One';
    margin-bottom: 80px;
}

#contact form {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

#contact form input,
#contact form textarea {
    width: 100%;
    height: 50px;
    margin: 5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #f9f1ed;
    color: #141313;
    border-radius: 5px;
}

#contact form textarea {
    height: 150px;
}

#contact form input[type="submit"] {
    height: 45px;
    background: linear-gradient(90deg, #bf2222 20%, #bf2222);
    color: #ffffff;
    text-transform: uppercase;
}

#footer {
    position: absolute;
    left: 0px;
    bottom: 0;
}

@media(max-width:620px) {
    #contact form {
        width: 90%;
    }
}

@media(max-width:1190px) {
    #main {
        background-size: 1150px !important;
    }
    .header-heading {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .header-heading h2 {
        font-size: 1.5rem;
    }
    .header-heading h1 {
        font-size: 3rem;
    }
    .header-heading .details {
        font-size: 1rem;
    }
    .pr-heading {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .pr-heading h2 {
        font-size: 1.5rem;
    }
    .pr-heading h1 {
        font-size: 3rem;
    }
    .pr-heading .details {
        font-size: 1rem;
    }
    .about-image img {
        height: 400px;
    }
    #products {
        height: auto;
    }
    .a-container {
        flex-wrap: wrap;
    }
    .a-box {
        flex-grow: 1;
    }
    .a-b-img img {
        object-fit: contain;
    }
}

@media(max-width:970px) {
    #main {
        height: 650px;
    }
    .header-heading {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .pr-heading {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .about-image {
        display: none;
    }
    .header-btn {
        margin-left: 20%;
        margin-top: 20px;
    }
    .pr-btn {
        margin-left: 20%;
        margin-top: 20px;
    }
    #about {
        justify-content: center;
        padding-top: 0px;
        text-align: center;
        margin-top: 0px;
    }
    .about-text {
        width: 90%;
        text-align: center;
    }
    .about-text h1 {
        font-size: 3rem;
    }
    .about-text p {
        width: 100%;
    }
    .about-text button {
        margin-left: 30%;
    }
    #products {
        margin-bottom: 100px;
    }
}

@media(max-width:600px) {
    .header-heading {
        width: 60%;
    }
    .pr-heading {
        width: 60%;
    }
}

@media(max-width:1100px) {
    .menu-icon {
        display: block;
    }
    nav {
        justify-content: space-between;
        height: 65px;
        padding: 0px 30px;
    }
    .logo img {
        width: 120px;
    }
    .header-btns {
        margin: 0;
    }
    .pr-btns {
        margin: 0;
    }
    .menu {
        display: none;
        position: absolute;
        top: 65px;
        left: 0px;
        background-color: #ffffff;
        border-bottom: 4px solid #FF1414;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
    .menu li {
        width: 100%;
    }
    nav .menu li a {
        width: 100%;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin: 0px;
        padding: 25px;
        border: 1px solid rgba(38, 38, 38, 0.03);
    }
    nav .menu-icon {
        cursor: pointer;
        float: right;
        padding: 28px 20px;
        position: relative;
        user-select: none;
    }
    nav .menu-icon .nav-icon {
        background-color: #3d2514;
        display: block;
        height: 2px;
        position: relative;
        transition: background 0.2s ease-out;
        width: 24px;
    }
    nav .menu-icon .nav-icon:before,
    nav .menu-icon .nav-icon:after {
        background: rgb(255, 255, 255);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all ease-out 0.2s;
        width: 100%;
    }
    nav .menu-icon .nav-icon:before {
        top: 6px;
        background-color: #3d2514;
    }
    nav .menu-icon .nav-icon:after {
        top: -6px;
        background-color: #3d2514;
    }
    nav .menu-btn:checked~.menu-icon .nav-icon {
        background: transparent;
    }
    nav .menu-btn:checked~.menu-icon .nav-icon:before {
        transform: rotate(-45deg);
        top: 0;
        background-color: #3d2514;
    }
    nav .menu-btn:checked~.menu-icon .nav-icon:after {
        transform: rotate(45deg);
        top: 0;
        background-color: #3d2514;
    }
    nav .menu-btn {
        display: none;
    }
    nav .menu-btn:checked~.menu {
        display: block;
    }
}

@media(max-width:400px) {
    .a-box {
        width: 100%;
        height: 400px;
    }
}